import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { imageUrlBuilder } from '../../utils/imageUrlBuilder';
import { clamp, continuousLoop } from '../../utils/mathHelpers';
import { fetchData } from './sequenceAPI';
import { formatSequence } from './sequenceModel';

const placeHolder = '/images/photo-placeholder.svg';

export type ISlideAction = 'forward' | 'backward' | number;

export type IOrientation = 'landscape' | 'portrait';

export type ISlideType = 'Unknown' | 'Property' | 'Image' | 'Video' | 'Text' | 'ContactDetails' | 'FiftyFifty';

export enum TextPositionTypeEnum {
  BeforeImage = 1,
  AfterImage = 2
}

export type IBorderOffset = {
  left?: string | number;
  right?: string | number;
  top?: string | number;
  bottom?: string | number;
};

export type IBorder = {
  firstClass: string;
  secondClass: string;
  firstOffset: IBorderOffset;
  secondOffset: IBorderOffset;
};

export interface ISlide {
  slideType: ISlideType;
  slideId: number;
  address1?: string;
  address2?: string;
  bathrooms?: number;
  bedrooms?: number;
  publicRooms?: number;
  propertyIconKey?: string;
  price?: string;
  priceInfo?: string;
  images?: string[];
  originalImages?: string[];
  imagesPreloaded: boolean;
  failedSlide?: boolean;
  underOffer?: boolean;
  closed?: boolean;
  closedMessage?: string;
  url?: string;
  header?: string;
  text?: string;
  floorArea?: number;
  epcRating?: string;
  councilTax?: string;
  fontColour?: string;
  backgroundColour?: string;
  textPosition?: TextPositionTypeEnum;
  borderClass?: IBorder;
}
export interface ISequence {
  sequenceId: number;
  name: string;
  solicitorName: string;
  solicitorLogo: string;
  fallbackImageUrl: string;
  displayHeader: boolean;
  slides: ISlide[];
}

export interface IDimensions {
  width?: number;
  height?: number;
}

export interface ImageUrl {
  landscapeUrl: string;
  portraitUrl?: string;
}

export interface ISequenceState {
  sequence: ISequence | null;
  slideCount: number;
  paused: boolean;
  status: 'idle' | 'loading' | 'failed';
  preloadStatus: 'idle' | 'loading' | 'failed';
  errorMessage: string | null;
  currentSlideIndex: number;
  orientation: IOrientation;
  mainPropertySlideImageDimensions: IDimensions | null;
  secondaryPropertySlideImageDimensions: IDimensions | null;
  imageSlideImageDimensions: IDimensions | null;
  fiftyFiftySlideImageDimensions: IDimensions | null;
  rotateImages: boolean;
}

const initialState: ISequenceState = {
  sequence: null,
  slideCount: 0,
  paused: false,
  status: 'loading',
  preloadStatus: 'loading',
  errorMessage: null,
  currentSlideIndex: 0,
  orientation: 'landscape',
  mainPropertySlideImageDimensions: null, //{ width: 1250, height: 800 },
  secondaryPropertySlideImageDimensions: null, //{ width: 600, height: 360 },
  imageSlideImageDimensions: null,
  fiftyFiftySlideImageDimensions: null,
  rotateImages: false
  // never has image rotatation (i.e. cycling), but going to leave it in the code in case it comes back
  // rotateImages: (window as any).REACT_APP_ROTATE_IMAGES === "true",
};

const preloadImages = async (imageArray: ImageUrl[]) => {
  const promises = imageArray.map(imageUrls => {
    return new Promise<string>(function(resolve, reject) {
      const img = new Image();
      let timer = 0;
      let attemptLandScape = false;

      const loadImage = (src: string) => {
        clearTimeout(timer);
        img.src = src;

        timer = window.setTimeout(() => {
          img.src = '';
        }, 8000);
      };

      img.onload = () => {
        clearTimeout(timer);
        if (imageUrls.portraitUrl && !attemptLandScape && img.naturalHeight > img.naturalWidth) {
          attemptLandScape = true;
          console.info('loading portrait url', imageUrls.portraitUrl);
          loadImage(imageUrls.portraitUrl);
        } else {
          resolve(img.src);
        }
      };
      img.onerror = img.onabort = () => {
        reject();
      };

      loadImage(imageUrls.landscapeUrl);
    });
  });

  return await Promise.allSettled(promises).then(imageResults => {
    const goodResults = (imageResults.filter(r => r.status === 'fulfilled') as PromiseFulfilledResult<string>[]).map(
      r => r.value
    );

    // backfill the rest with the placeholder, but if there is only one image (image, or fifty fifty), then don't backfill
    const imageResultsLength = imageResults.length;
    const backfill = imageResults.length - goodResults.length;
    if (imageResultsLength === 1 && backfill === 1) {
      return [];
    } else {
      return [...goodResults, ...Array(imageResults.length - goodResults.length).fill(placeHolder)];
    }
  });
};

export const loadDataAsync = createAsyncThunk(
  'sequence/loadData',
  async (id: number, { getState, rejectWithValue }) => {
    try {
      const response = await fetchData(id);

      if (response.ok) {
        // state is after fetchData because loadDataAsync seems to fire before the useEffect in the App.tsx that sets the orientation.
        const state = getState() as RootState;
        const sequence = await response.json();

        const formattedSequence = formatSequence(sequence, state.sequence.orientation);

        return { sequence: formattedSequence };
      }

      const { ExceptionMessage: exceptionMessage } = await response.json();

      throw new Error(exceptionMessage);
    } catch (error) {
      console.log(error);
      return rejectWithValue((error as any).message);
    }
  }
);

export const loadSlideImagesAsync = createAsyncThunk(
  'sequence/loadSlideImages',
  async (slideIndex: number, { getState, rejectWithValue }) => {
    const state = getState() as RootState;

    const slide = state.sequence.sequence?.slides[slideIndex];

    let imageArray;
    if (slide?.slideType === 'Property') {
      const primaryImageForPreload = imageUrlBuilder(
        slide.originalImages?.[0],
        state.sequence.mainPropertySlideImageDimensions?.width,
        state.sequence.mainPropertySlideImageDimensions?.height
      );

      const secondaryImagesForPreload =
        slide.originalImages
          ?.slice(1)
          .map(image =>
            imageUrlBuilder(
              image,
              state.sequence.secondaryPropertySlideImageDimensions?.width,
              state.sequence.secondaryPropertySlideImageDimensions?.height
            )
          ) || [];
      imageArray = [primaryImageForPreload, ...secondaryImagesForPreload];
    }

    if (slide?.slideType === 'Image' || slide?.slideType === 'FiftyFifty') {
      imageArray = [
        imageUrlBuilder(
          slide.url,
          slide.slideType === 'Image'
            ? state.sequence.imageSlideImageDimensions?.width
            : state.sequence.fiftyFiftySlideImageDimensions?.width,
          slide.slideType === 'Image'
            ? state.sequence.imageSlideImageDimensions?.height
            : state.sequence.fiftyFiftySlideImageDimensions?.height,
          true
        )
      ];
    }

    let preloadedImages: string[] = [];

    if (imageArray) {
      preloadedImages = await preloadImages(imageArray);
    }

    return { preloadedImages, slideIndex };
  }
);

const nextSlideAsync = createAsyncThunk(
  'sequence/nextSlide',
  async (action: ISlideAction, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState;

    let newSlideIndex = 0;
    const count = state.sequence.slideCount;

    if (action === 'forward' || action === 'backward') {
      // go forwards or backwards
      newSlideIndex = continuousLoop(state.sequence.currentSlideIndex, action === 'forward' ? 1 : -1, count);
    } else {
      // go straight to the index
      newSlideIndex = clamp(action, 0, count - 1);
    }

    // load up this slide and see if the images need to be preloaded
    const thisSlide = state.sequence.sequence?.slides[newSlideIndex];

    let slideFailed = thisSlide?.failedSlide;

    // This is used if jumping straight to a slide
    if (!slideFailed && thisSlide && !thisSlide.imagesPreloaded) {
      console.info('preloading slide', newSlideIndex);
      const result = await dispatch(loadSlideImagesAsync(newSlideIndex));
      slideFailed = !(result.payload as any).preloadedImages.length;
    }

    const nextSlideIndex = continuousLoop(newSlideIndex, 1, count);

    if (slideFailed) {
      console.warn(`Slide ${newSlideIndex} failed. Skipping to the next one`);
      dispatch(getSlide(nextSlideIndex));
      return rejectWithValue(false);
    }

    // load up the next slides images
    const nextSlide = state.sequence.sequence?.slides[nextSlideIndex];
    if (nextSlide && !nextSlide.imagesPreloaded) {
      // don't await this one, it should load in the background
      console.info('preloading next slide ', nextSlideIndex);
      dispatch(loadSlideImagesAsync(nextSlideIndex));
    }

    console.info(`Showing slide ${newSlideIndex} - ${thisSlide?.slideType} ${thisSlide?.slideId}`);

    return { newSlideIndex };
  }
);

// This is wrapper because createAsyncThunk doesn't allow optional parameters
export const getSlide = (action: ISlideAction = 'forward') => nextSlideAsync(action);

export const sequenceSlice = createSlice({
  name: 'sequence',
  initialState,
  reducers: {
    startPreviewLoad: state => {
      state.status = 'loading';
    },
    failLoadPreview: state => {
      state.status = 'failed';
    },
    togglePaused: (state, action: PayloadAction<boolean | undefined>) => {
      state.paused = typeof action.payload === 'boolean' ? action.payload : !state.paused;

      console.info(`Slide show pause is ${state.paused.toString()}`);
    },
    setOrientation: (state, action: PayloadAction<'portrait' | 'landscape'>) => {
      state.orientation = action.payload;
    },
    setMainPropertySlideImageDimensions: (state, action: PayloadAction<IDimensions>) => {
      state.mainPropertySlideImageDimensions = action.payload;
    },
    setSecondaryPropertySlideImageDimensions: (state, action: PayloadAction<IDimensions>) => {
      state.secondaryPropertySlideImageDimensions = action.payload;
    },
    setImageSlideImageDimensions: (state, action: PayloadAction<IDimensions>) => {
      state.imageSlideImageDimensions = action.payload;
    },
    setFiftyFiftySlideImageDimensions: (state, action: PayloadAction<IDimensions>) => {
      state.fiftyFiftySlideImageDimensions = action.payload;
    },
    loadData: (state, action: PayloadAction<any>) => {
      const sequence = formatSequence(action.payload, state.orientation);

      state.sequence = sequence;

      state.slideCount = sequence.slides.length;

      state.status = 'idle';
      state.preloadStatus = 'idle';
    }
  },

  extraReducers: builder => {
    builder
      .addCase(loadDataAsync.pending, state => {
        state.status = 'loading';
      })
      .addCase(loadDataAsync.fulfilled, (state, action) => {
        const sequence = action.payload.sequence;

        state.sequence = sequence;

        state.slideCount = sequence.slides.length;

        // setting status idle from here was interferring with the getSlide which was doing the image lookup for each slide.
      })
      .addCase(loadDataAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload as string;
      })
      .addCase(loadSlideImagesAsync.pending, (state, action) => {
        state.preloadStatus = 'loading';
      })
      .addCase(loadSlideImagesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        const slideIndex = action.payload.slideIndex;
        const images = action.payload.preloadedImages;

        state.preloadStatus = 'idle';

        // get the slide. Not sure how immer deals with this type of data, so going a bit old school on it
        const copySlides = state.sequence?.slides || [];
        copySlides[slideIndex].images = images;
        copySlides[slideIndex].failedSlide = false;

        // If there are any placeholder images, then don't set the preload. Let it try and load again on the next loop
        if (!images.find(x => x === placeHolder)) {
          copySlides[slideIndex].imagesPreloaded = true;
        }

        if (!images.length) {
          // if there are no images, then mark this slide as failed and skip it. Try again the next round
          copySlides[slideIndex].failedSlide = true;
        }

        state.sequence && (state.sequence.slides = copySlides);
      })
      .addCase(nextSlideAsync.fulfilled, (state, action) => {
        state.preloadStatus = 'idle';

        state.currentSlideIndex = action.payload.newSlideIndex;
      })
      .addCase(nextSlideAsync.rejected, state => {
        state.preloadStatus = 'idle';
      });
  }
});

export const {
  loadData,
  startPreviewLoad,
  failLoadPreview,
  togglePaused,
  setOrientation,
  setMainPropertySlideImageDimensions,
  setSecondaryPropertySlideImageDimensions,
  setImageSlideImageDimensions,
  setFiftyFiftySlideImageDimensions
} = sequenceSlice.actions;

export const selectSequence = (state: RootState) => state.sequence;

export const selectSolicitorName = (state: RootState) => state.sequence.sequence?.solicitorName;

const sequenceShowHeader = (state: RootState) => state.sequence.sequence?.displayHeader === true;

export const selectActiveContent = (state: RootState) => {
  return state.sequence.sequence?.slides[state.sequence.currentSlideIndex];
};

export const selectShowHeader = createSelector(
  [sequenceShowHeader, selectActiveContent],
  (sequenceShowHeader, currentSlide) => {
    return sequenceShowHeader;
  }
);

export const selectSequenceStatus = (state: RootState) => state.sequence.status;
export const selectPreloadStatus = (state: RootState) => state.sequence.preloadStatus;
export const selectErrorMessage = (state: RootState) => state.sequence.errorMessage;

export const selectFallbackImageUrl = (state: RootState) => state.sequence.sequence?.fallbackImageUrl;

export const selectOrientation = (state: RootState) => state.sequence.orientation;

export const selectMainPropertySlideImageDimensions = (state: RootState) =>
  state.sequence.mainPropertySlideImageDimensions;
export const selectSecondaryPropertySlideImageDimensions = (state: RootState) =>
  state.sequence.secondaryPropertySlideImageDimensions;
export const selectImageSlideImageDimensions = (state: RootState) => state.sequence.imageSlideImageDimensions;
export const selectFiftyFiftySlideImageDimensions = (state: RootState) => state.sequence.fiftyFiftySlideImageDimensions;

export default sequenceSlice.reducer;
