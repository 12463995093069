import { useEffect } from 'react';
import { useAppDispatch } from '../app/hooks';
import { MediaSequencer } from '../features/sequence/MediaSequencer';
import { failLoadPreview, loadData, startPreviewLoad } from '../features/sequence/sequenceSlice';

export function PreviewPage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(startPreviewLoad());
  }, [dispatch]);

  useEffect(() => {
    const origin = window.opener;

    if (!origin) {
      dispatch(failLoadPreview());
    } else {
      origin.postMessage({ opened: true }, '*');
    }
  }, [dispatch]);

  window.addEventListener(
    'message',
    event => {
      if (event.origin !== (window as any).REACT_APP_OFFICE_URL) return;
      console.info(event);
      try {
        const decodedMessage = JSON.parse(new TextDecoder(document.characterSet.toLowerCase()).decode(event.data));
        dispatch(loadData(decodedMessage));
      } catch (error) {
        dispatch(failLoadPreview());
      }
    },
    false
  );

  return <MediaSequencer preview />;
}
