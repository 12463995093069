import { animated } from "@react-spring/web";
import { ISlide } from "../features/sequence/sequenceSlice";
import { useTransitionFactory } from "../hooks/useTransitionFactory";
import { Borders } from "./Borders";
import { FiftyFiftyTemplate } from "./templates/FiftyFiftyTemplate";
import { ImageTemplate } from "./templates/ImageTemplate";
import { PropertyTemplate } from "./templates/PropertyTemplate";
import { TextTemplate } from "./templates/TextTemplate";
import { VideoTemplate } from "./templates/VideoTemplate";

interface Props {
    slideInfo: ISlide | undefined;
    solicitorName: string;
    fallbackImage: string;
}

function TemplateSelector({ slideInfo, solicitorName, fallbackImage }: Props) {
    switch (slideInfo?.slideType) {
        case "Text":
            return <TextTemplate slideInfo={slideInfo} />;
        case "Image":
            return <ImageTemplate slideInfo={slideInfo} fallbackImage={fallbackImage} />;
        case "Video":
            return <VideoTemplate slideInfo={slideInfo} />;
        case "FiftyFifty":
            return <FiftyFiftyTemplate slideInfo={slideInfo} fallbackImage={fallbackImage} />;
        default:
            return (
                <PropertyTemplate
                    slideInfo={slideInfo}
                    solicitorName={solicitorName}
                />
            );
    }
}

export function AnimatedTemplate({
    slideInfo,
    solicitorName,
    fallbackImage,
}: Props) {
    const transition = useTransitionFactory(slideInfo as Record<string, any>);

    return (
        <div id="templateSelector" className="template-selector">
            {transition((styles, item) => 
                <animated.div
                    className="template-selector__animated"
                    style={styles}
                    id={`${item.slideType}template`}
                >
                    {item.slideType === "Property" ? 
                        <TemplateSelector
                                slideInfo={item}
                                solicitorName={solicitorName}
                                fallbackImage={fallbackImage}
                            />
                    :
                    <div className="border-template">
                        <TemplateSelector
                            slideInfo={item}
                            solicitorName={solicitorName}
                            fallbackImage={fallbackImage}
                        />
                        <Borders borderClass={item.borderClass} />
                    </div>}


                </animated.div>   
            )}
        </div>
    );
}
