import tinycolor from 'tinycolor2';
import { calculateBorders } from '../../utils/borderCalculator';
import { clamp } from '../../utils/mathHelpers';
import { IOrientation, ISequence, ISlide } from './sequenceSlice';

export function formatText(slide: Record<string, any>): Pick<ISlide, 'header' | 'text' | 'imagesPreloaded'> {
  return {
    header: slide.Header,
    text: slide.Text,
    imagesPreloaded: true
  };
}

export function formatImage(slide: Record<string, any>): Pick<ISlide, 'url'> {
  return {
    url: slide.Url
  };
}

export function formatVideo(slide: Record<string, any>): Pick<ISlide, 'url' | 'imagesPreloaded'> {
  return {
    url: slide.YouTubeId,
    imagesPreloaded: true
  };
}

export function formatFiftyFifty(slide: Record<string, any>): Pick<ISlide, 'url' | 'header' | 'text' | 'textPosition'> {
  return {
    url: slide.Url,
    header: slide.Header,
    text: slide.Text,
    textPosition: clamp(Number(slide.TextPositionType), 1, 2)
  };
}

export function formatProperty(
  property: Record<string, any>
): Omit<ISlide, 'slideId' | 'url' | 'slideType' | 'header' | 'text'> {
  return {
    address1: property.Address1,
    address2: property.Address2,
    bathrooms: property.Bathrooms,
    bedrooms: property.Bedrooms,
    publicRooms: property.PublicRooms,
    propertyIconKey: property.PropertyIconKey,
    price: `£${property.Price.split('£')[1]}`,
    priceInfo: property.Price.split('£')[0],
    images: property.Images,
    originalImages: property.Images,
    imagesPreloaded: false,
    underOffer: property.UnderOffer,
    closed: property.Closed,
    closedMessage: property.ClosedMessage,
    floorArea: property.FloorArea,
    epcRating: property.EpcRating,
    councilTax: property.CouncilTax
  };
}

export function formatSlide(slide: Record<string, any>, isPortrait: boolean): ISlide | undefined {
  const data: ISlide = {
    slideId: slide.Data.Id,
    imagesPreloaded: false,
    slideType: slide.SlideType
  };

  let mappedData: Partial<ISlide> = {};

  try {
    if (slide.SlideType === 'Property') mappedData = formatProperty(slide.Data);
    else if (slide.SlideType === 'Image') mappedData = formatImage(slide.Data);
    else if (slide.SlideType === 'Text') mappedData = formatText(slide.Data);
    else if (slide.SlideType === 'FiftyFifty') mappedData = formatFiftyFifty(slide.Data);
    else if (slide.SlideType === 'Video') mappedData = formatVideo(slide.Data);
    else throw new Error('Invalid Slide Type');

    // These are used in a few of the slides, so easier to map in one place
    if (slide.Data.HasBorder) {
      data.borderClass = calculateBorders(isPortrait);
    }

    data.fontColour = ('#' + (slide.Data.FontColour || '4C4C4C')).replace(/#+/, '#');
    data.backgroundColour = ('#' + (slide.Data.PageBackgroundColour || 'F2F2F2')).replace(/#+/, '#');

    // check the colour contrasts. Set to black or white if not readable
    if (!tinycolor.isReadable(data.fontColour, data.backgroundColour, { level: 'AA', size: 'large' })) {
      data.fontColour = tinycolor
        .mostReadable(data.backgroundColour, [data.fontColour, '#000', '#FFF'], {
          level: 'AA',
          size: 'large'
        })
        .toHexString();
    }

    return { ...data, ...mappedData };
  } catch (error) {
    console.error(error);
    return;
  }
}

export function formatSequence(sequence: Record<string, any>, orientation: IOrientation): ISequence {
  return {
    sequenceId: sequence.Id,
    name: sequence.Name,
    slides: sequence.Slides.map((slide: Record<string, any>[]) =>
      formatSlide(slide, orientation === 'portrait')
    ).filter((slide: ISlide | undefined) => slide !== undefined),
    solicitorName: sequence.SolicitorName,
    solicitorLogo: sequence.SolicitorLogo,
    fallbackImageUrl: sequence.FallBackImageUrl,
    displayHeader: sequence.DisplayAspcLogo
  };
}
