import { ImageUrl } from "../features/sequence/sequenceSlice";

export const imageUrlBuilder = (url?: string, width?: number, height?: number, noPortrait?: boolean) => {
    const urls: ImageUrl = {
        landscapeUrl: `${url}?width=${width}${noPortrait ? `height=${height}` : ""}`
    };

    if (!noPortrait) {
        urls.portraitUrl = `${url}?width=${width}&height=${height}&mode=boxpad`;
    }

    return urls;
};
