import { IBorder } from "../features/sequence/sequenceSlice";

const randomBit = () => randomIntFromInterval(0, 1);

function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}

// this will look smaller on 4k screens
const offsetLimits = {
    x: { min: 300, max: 800 },
    y: { min: 60, max: 200 }
};

export const calculateBorders = (isPortrait: boolean): IBorder => {
    // border will always be opposite sides
    // portrait will always have top and bottom borders, the second will be diagonal from the first
    // landscape can have both borders on the top level, but not bottom
    // both colours will alternate, but not be the same
    // get a random boolean for the top and bottom position and the colour.
    const colours = ["dark", "light"];

    const isTop = randomBit();
    const firstY = isTop ? "top" : "bottom";
    const secondY = isPortrait || !isTop ? (isTop ? "bottom" : "top") : randomBit() ? "top" : "bottom";
    const firstColour = colours.splice(Number(randomBit()), 1);
    const secondColour = colours[0];

    // build up the border classes. The x,y positioning happens here those.
    // The offset limits are not entirely arbitrary, they represent a point in a rectangular space that seems optimal to the corner of the
    // rotated rectangle to be pointing at
    return {
        firstClass: `border border--${firstY}left border--${firstColour}`,
        secondClass: `border border--${secondY}right border--${secondColour}`,
        firstOffset: {
            left: randomIntFromInterval(offsetLimits.x.min, offsetLimits.x.max),
            [firstY]: randomIntFromInterval(offsetLimits.y.min, offsetLimits.y.max)
        },
        secondOffset: {
            right: randomIntFromInterval(offsetLimits.x.min, offsetLimits.x.max),
            [secondY]: randomIntFromInterval(offsetLimits.y.min, offsetLimits.y.max)
        }
    };
};
