import { ISlide } from "../../features/sequence/sequenceSlice";

interface Props {
    slideInfo: ISlide | undefined;
    fallbackImage: string;
}

export function ImageTemplate({ slideInfo, fallbackImage }: Props) {
    return (
        <div
            className="image-template"
            style={{
                backgroundColor: slideInfo?.backgroundColour,
            }}
        >
            <img
                alt=""
                src={slideInfo && slideInfo.images ? slideInfo?.images[0] : fallbackImage}
                data-testid="image"
            />
        </div>
    );
}
