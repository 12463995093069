import { useCallback, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './app/hooks';
import {
  ISlideAction,
  getSlide,
  selectActiveContent,
  selectSequence,
  setOrientation,
  togglePaused
} from './features/sequence/sequenceSlice';
import { useDevDetect } from './hooks/useDevDetect';
import { usePortraitDetect } from './hooks/usePortraitDetect';
import { MediaPage } from './pages/MediaPage';
import { PreviewPage } from './pages/PreviewPage';
import { TestingPage } from './pages/TestingPage';

const delay = parseInt((window as any).REACT_APP_TRANSITION_DELAY_SECONDS || '');
const refreshTime = parseFloat((window as any).REACT_APP_REFRESH_MINUTES || 15) * 60 * 1000;

let slideTimer = 0;
let refreshTimer = 0;

function App() {
  const devMode = useDevDetect();

  const dispatch = useAppDispatch();
  const portrait = usePortraitDetect();

  // auto reload the page state checkers
  const [sequenceComplete, setSequenceComplete] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);

  const { preloadStatus, paused, status } = useAppSelector(selectSequence);
  const { rotateImages } = useAppSelector(selectSequence);
  const activeSlide = useAppSelector(selectActiveContent);
  const { currentSlideIndex, slideCount } = useAppSelector(selectSequence);
  const slideType = activeSlide?.slideType;

  const preloadingDone = preloadStatus === 'idle';
  const isLoading = status === 'loading';

  // Rotating properties and videos handle their own "next slide" functionality
  const canLoadNext =
    !paused && !isLoading && preloadingDone && slideType !== 'Video' && (!rotateImages || slideType !== 'Property');

  const handleSlide = useCallback(
    (action: ISlideAction = 'forward') => {
      clearTimeout(slideTimer);
      dispatch(getSlide(action));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(setOrientation(portrait ? 'portrait' : 'landscape'));
  }, [dispatch, portrait]);

  useEffect(() => {
    if (canLoadNext) {
      slideTimer = window.setTimeout(() => {
        if (currentSlideIndex + 1 >= slideCount) {
          setSequenceComplete(true);
        }
        handleSlide();
        slideTimer = 0;
      }, delay * 1000);
    }
    return () => clearTimeout(slideTimer);
  }, [dispatch, handleSlide, canLoadNext, currentSlideIndex, slideCount]);

  useEffect(() => {
    refreshTimer = window.setTimeout(() => {
      setRefreshPage(true);
    }, refreshTime);
    return () => clearTimeout(refreshTimer);
  }, []);

  useEffect(() => {
    if (sequenceComplete && refreshPage && !paused) {
      window.location.reload();
    }
  }, [sequenceComplete, refreshPage, paused]);

  useEffect(() => {
    const clickHandler = () => {
      console.info('forward slide from click');
      dispatch(togglePaused(true));
      handleSlide();
    };
    const keyHandler = (e: KeyboardEvent) => {
      let direction: ISlideAction | undefined = undefined;
      if (e.key !== 'p') dispatch(togglePaused(true));
      switch (e.key) {
        case 'ArrowLeft':
        case 'a':
          direction = 'backward';
          break;
        case 'ArrowRight':
        case 'd':
        case ' ':
          direction = 'forward';
          break;
        case 'p':
          clearTimeout(slideTimer);
          dispatch(togglePaused());
          break;
      }

      if (direction) {
        console.info(`${direction} slide from keypress "${e.key}"`);
        handleSlide(direction);
      }
    };

    window.addEventListener('click', clickHandler);
    window.addEventListener('keyup', keyHandler);

    // expose the method to the window for console access
    (window as any).getSlide = handleSlide;

    return () => {
      window.removeEventListener('click', clickHandler);
      window.removeEventListener('keyup', keyHandler);
      delete (window as any).getSlide;
    };
  }, [dispatch, handleSlide]);

  return (
    <Router>
      <Routes>
        <Route path=":id" element={<MediaPage />} />
        <Route path="preview" element={<PreviewPage />} />
        {devMode && <Route path="test" element={<TestingPage />} />}
      </Routes>
    </Router>
  );
}

export default App;
