import { Fragment } from "react";
import { useAppSelector } from "../app/hooks";
import { ISlide, selectSequenceStatus } from "../features/sequence/sequenceSlice";
import { ReactComponent as BathroomLogo } from "../images/feature-bath.svg";
import { ReactComponent as BedroomLogo } from "../images/feature-bed.svg";
import { ReactComponent as PublicRoomLogo } from "../images/feature-couch.svg";
import { ReactComponent as PropertyDetachedLogo } from "../images/feature-detached.svg";
import { ReactComponent as PropertyFlatLogo } from "../images/feature-flat.svg";
import { ReactComponent as PropertySemiDetachedLogo } from "../images/feature-semi-detached.svg";
import { ReactComponent as PropertyTerracedLogo } from "../images/feature-terrace.svg";

const mapPropertyIconKey = (key?: string) => {
    switch (key) {
        case "HOUSE_SEMI_DETACHED":
            return <PropertySemiDetachedLogo className="property-type property-type-semi-detached" />;
        case "HOUSE_TERRACED":
            return <PropertyTerracedLogo className="property-type property-type-terraced" />;
        case "FLAT":
            return <PropertyFlatLogo className="property-type property-type-flat" />;
        default:
            return <PropertyDetachedLogo className="property-type property-type-detached" />;
    }
};

interface Props {
    name?: string;
    propertyData?: Partial<ISlide>;
}

export function InfoBar({ name, propertyData = {} }: Props) {
    const showLoading = useAppSelector(selectSequenceStatus) !== "idle";

    const {
        slideId,
        address1,
        address2,
        bedrooms,
        bathrooms,
        publicRooms,
        price,
        priceInfo,
        floorArea,
        epcRating,
        // councilTax,
        propertyIconKey
    } = propertyData;

    const propertyType = mapPropertyIconKey(propertyIconKey);
    const loadingSpace = showLoading ? <Fragment>&nbsp;</Fragment> : "";

    return (
        <div id="grid" className="property-info" data-loading={showLoading}>
            <div>
                <h1 className="property-heading-text">{loadingSpace || address1}</h1>
                <p className="property-sub-address">{loadingSpace || address2}</p>
                <div className="features-bar">
                    <span id="bedrooms" className="rooms bedroom">
                        <BedroomLogo />
                        {loadingSpace || bedrooms}
                    </span>
                    <span id="publicrooms" className="rooms publicrooms">
                        <PublicRoomLogo />
                        {loadingSpace || publicRooms}
                    </span>
                    <span id="bathrooms" className="rooms bathrooms">
                        <BathroomLogo />
                        {loadingSpace || bathrooms}
                    </span>

                    {propertyType}

                    {(loadingSpace || !!floorArea) && (
                        <Fragment>
                            <span className="separator">|</span>
                            <span id="floorarea" className="bold-font">
                                {loadingSpace || floorArea}
                                m2
                            </span>
                        </Fragment>
                    )}
                    <span className="separator">|</span>
                    <span>
                        <span className="light-font">EPC</span>
                        <span id="epc" className="bold-font">
                            {loadingSpace || epcRating}
                        </span>
                    </span>
                </div>
            </div>

            <div className="price-bar">
                <p data-loading={showLoading} className="property-sub-text">
                    {loadingSpace || priceInfo}
                </p>
                <p data-loading={showLoading} className="property-heading-text">
                    {loadingSpace || price}
                </p>
                <p data-loading={showLoading} className="aspc-ref property-sub-text">
                    {/* https://equator.atlassian.net/browse/ASPC-4382 Only showing "ASPC" now, so ignoring what is in the api */}
                    ASPC (Ref: {slideId})
                </p>
            </div>
        </div>
    );
}
