interface Props {
    underOffer?: boolean;
    closed?: boolean;
    closedMessage?: string;
}

export function CustomMessage({ underOffer, closed, closedMessage }: Props) {
    const message = underOffer ? "Under Offer" : closed ? closedMessage : null;

    return message ? (
        <div className="custom-message" id="custommessage">
            {message}
        </div>
    ) : null;
}
