import { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FiftyFiftyTemplate } from "../../components/templates/FiftyFiftyTemplate";
import { PropertyTemplate } from "../../components/templates/PropertyTemplate";
import { AnimatedTemplate } from "../../components/TemplateSelector";
import {
    getSlide,
    ISlide,
    loadDataAsync,
    selectActiveContent,
    selectFallbackImageUrl,
    selectSequenceStatus,
    selectSolicitorName
} from "./sequenceSlice";

interface Props {
    preview?: boolean;
}

export function MediaSequencer({ preview }: Props) {
    const slideInfo = useAppSelector(selectActiveContent);
    const solicitorName = useAppSelector(selectSolicitorName);
    const status = useAppSelector(selectSequenceStatus);

    const fallbackImage = useAppSelector(selectFallbackImageUrl);

    const isLoading = status === "loading";
    const hasFailed = status === "failed";

    const dispatch = useAppDispatch();

    const { id } = useParams();
    const sequenceId = parseInt(id!);

    useEffect(
        () => {
            if (!preview && hasFailed) {
                window.setTimeout(() => window.location.reload(), 10000);
            }
        },
        [preview, hasFailed]
    );

    useEffect(
        () => {
            if (!preview && sequenceId) {
                dispatch(loadDataAsync(sequenceId)).then(() => {
                    dispatch(getSlide(0));
                });
            }
        },
        [dispatch, sequenceId, preview]
    );

    if (isLoading) {
        return (
            <div className="template-selector">
                <div className="template-selector__animated">
                    <PropertyTemplate />
                </div>
                <div className="template-selector__animated">
                    <div className="border-template">
                        <FiftyFiftyTemplate />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <AnimatedTemplate
                slideInfo={slideInfo as ISlide}
                solicitorName={solicitorName || ""}
                fallbackImage={fallbackImage || ""}
            />

            {status === "failed" && (
                <div className="snackbar">
                    {preview
                        ? `A preview is only available when accessed through the Office website`
                        : `Looks like something went wrong with Id:${id}. Try again shortly...`}
                </div>
            )}
        </>
    );
}
