import { useEffect, useState } from "react";
import YouTube, { YouTubeEvent } from "react-youtube";
import { useAppDispatch } from "../../app/hooks";
import { getSlide, ISlide } from "../../features/sequence/sequenceSlice";

interface Props {
    slideInfo: ISlide | undefined;
}

let videoTimer = 0;
let backupTimer = 0;

export function VideoTemplate({ slideInfo }: Props) {
    const dispatch = useAppDispatch();
    const [showVideo, setShowVideo] = useState(false);

    const youtubeId = slideInfo?.url!;

    const handleEnd = () => {
        clearTimeout(backupTimer);
        dispatch(getSlide());
    };

    const handleVideoReady = (e: YouTubeEvent) => {
        clearTimeout(videoTimer);

        const player = e.target;
        const duration = player.getDuration();
        // The timeout offset 0.3 matches the styles.scss transition for the video
        videoTimer = window.setTimeout(() => {
            setShowVideo(false);
        }, (duration - 0.3) * 1000);

        // in case the video doesn't run
        backupTimer = window.setTimeout(() => {
            dispatch(getSlide());
        }, (duration + 1) * 1000);
    }

    useEffect(
        () => {
            setShowVideo(true);
            return () => {
                clearTimeout(videoTimer);
                clearTimeout(backupTimer);
            };
            },
        [youtubeId]
    );

    return (
        <div className="video" data-state={showVideo ? "playing" : "ending"}>
            <YouTube
                id="videoplayer"
                videoId={youtubeId}
                opts={{
                    height: "100%",
                    width: "100%",
                    playerVars: { autoplay: 1, mute: 1, controls: 0, showinfo: 0, autohide: 1, modestbranding: 1, rel: 0 }
                }}
                style={{ width: "100%", height: "100%" }}
                onError={handleEnd}
                onEnd={handleEnd}
                onReady={handleVideoReady}
            />
        </div>
    );
}
