import { useEffect } from "react";
import { useAppDispatch } from "../app/hooks";
import {
    fiftyFiftySlide,
    imageSlide,
    propertySlide1,
    propertySlide2,
    responseBody,
    textSlide,
    videoSlide
} from "../constants/sampleResponses";
import { MediaSequencer } from "../features/sequence/MediaSequencer";
import { loadData, startPreviewLoad } from "../features/sequence/sequenceSlice";

export function TestingPage() {
    const response = responseBody([fiftyFiftySlide, imageSlide, propertySlide1, propertySlide2, textSlide, videoSlide]);

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(startPreviewLoad());

        dispatch(loadData(response));
    });

    return <MediaSequencer preview />;
}
