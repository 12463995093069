import { IBorder } from "../features/sequence/sequenceSlice";

interface Params {
    borderClass?: IBorder;
}

export const Borders: React.FC<Params> = ({ borderClass }) => {
    if (!borderClass) return null;

    return (
        <>
            <div className={borderClass.firstClass} style={borderClass.firstOffset} />
            <div className={borderClass.secondClass} style={borderClass.secondOffset} />
        </>
    );
};
