import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    getSlide,
    ISlide,
    selectMainPropertySlideImageDimensions, selectSecondaryPropertySlideImageDimensions,
    selectSequence,
    selectSequenceStatus,
    selectShowHeader,
    setMainPropertySlideImageDimensions,
    setSecondaryPropertySlideImageDimensions
} from "../../features/sequence/sequenceSlice";
import { useContainerSizeDetect } from "../../hooks/useContainerSizeDetect";
import { CustomMessage } from "../CustomMessage";
import { Header } from "../Header";
import { InfoBar } from "../InfoBar";
import { TransitionImage } from "../TransitionImage";

interface Props {
    slideInfo?: ISlide;
    solicitorName?: string;
}

const delay = parseInt((window as any).REACT_APP_TRANSITION_DELAY_SECONDS || "");

let propertyImageTimout = 0;

export function PropertyTemplate({ slideInfo, solicitorName }: Props) {
    const dispatch = useAppDispatch();
    const showHeader = useAppSelector(selectShowHeader);
    const showLoading = useAppSelector(selectSequenceStatus) !== "idle";

    const mainImageRef = useRef<HTMLDivElement>(null);
    const secondaryImageRef = useRef<HTMLDivElement>(null);

    const [imageStartIndex, setImageStartIndex] = useState(0);

    const {rotateImages} = useAppSelector(selectSequence);
    const baseImages = slideInfo?.images;
    const imageCount = baseImages?.length || 0;

    const currentMainImage = useContainerSizeDetect(mainImageRef);
    const currentSecondaryImage = useContainerSizeDetect(secondaryImageRef);

    const mainImageDimensions = useAppSelector(selectMainPropertySlideImageDimensions);
    const secondaryImageDimensions = useAppSelector(selectSecondaryPropertySlideImageDimensions);

    const images = useMemo(() => {
        if( !baseImages ) return [];
        
        return baseImages.slice(imageStartIndex).concat(baseImages.slice(0, imageStartIndex));
    },[imageStartIndex, baseImages]);

    useEffect(() => {
        clearTimeout(propertyImageTimout);
        setImageStartIndex(0);
    },[baseImages]);

    useEffect(() => {
        if( rotateImages && imageCount ) {
            propertyImageTimout = window.setTimeout(() => {
                if( imageStartIndex < imageCount ) {
                    setImageStartIndex(imageStartIndex + 1);
                } else {
                    dispatch(getSlide());
                }
            }, delay * 1000 );
        }
    },[dispatch, rotateImages, imageStartIndex, imageCount]);

    useEffect(() => {
        if (
            (mainImageDimensions === null || secondaryImageDimensions === null) &&
            currentMainImage &&
            currentSecondaryImage
        ) {
            dispatch(setMainPropertySlideImageDimensions(currentMainImage));
            dispatch(
                setSecondaryPropertySlideImageDimensions(currentSecondaryImage)
            );
        }
    }, [
        currentMainImage,
        currentSecondaryImage,
        dispatch,
        mainImageDimensions,
        secondaryImageDimensions,
    ]);

    return (
        <>
        {showHeader && <Header />}
        <div className="property-container">
            <div className="property-images">
                <div className="property-image property-image--main" data-loading={showLoading} ref={mainImageRef}>
                    {!showLoading && <TransitionImage imageUrl={images[0]} id="primaryimage">
                        <CustomMessage
                            underOffer={slideInfo?.underOffer}
                            closed={slideInfo?.closed}
                            closedMessage={slideInfo?.closedMessage}
                        />
                    </TransitionImage>}
                </div>
                <div className="property-images-small">
                    {images?.slice(1,3).map((image,index) => 
                        <div key={index} className="property-image property-image--small">
                            <TransitionImage imageUrl={image} id={"secondaryImage" + (index + 1)} />
                        </div>
                    )}
                    {showLoading && <><div className="property-image" data-loading={showLoading} ref={secondaryImageRef}></div><div className="property-image" data-loading={showLoading}></div></>}
                </div>
                <div className="property-images-small">
                    {images?.slice(3,5).map((image,index) => 
                        <div key={index} className="property-image property-image--small">
                            <TransitionImage imageUrl={image} id={"secondaryImage" + (index + 3)} />
                        </div>
                    )}
                    {showLoading && <><div className="property-image" data-loading={showLoading}></div><div className="property-image" data-loading={showLoading}></div></>}
                </div>
            </div>
            <InfoBar
                name={solicitorName}
                propertyData={slideInfo}
            />
        </div>
        </>
    );
}
