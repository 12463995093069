import { ISlide } from "../../features/sequence/sequenceSlice";

interface Props {
    slideInfo: ISlide | undefined;
}

export function TextTemplate({ slideInfo }: Props) {
    return (
        <div className="text-template" style={{
            backgroundColor: slideInfo?.backgroundColour,
            color: slideInfo?.fontColour
        }}>
            <div className="text-content">
                <h1 className="template-heading-text">{slideInfo?.header || "Placeholder Header Text"}</h1>
                <div className="template-sub-text" dangerouslySetInnerHTML={{ __html: slideInfo?.text || "Placeholder Content Text" }} />
            </div>
        </div>
    );
}
