import { animated } from "@react-spring/web";
import React from "react";
import { useTransitionFactory } from "../hooks/useTransitionFactory";

interface Props {
    imageUrl?: string;
    id: string;
    children?: React.ReactNode;
}

export const TransitionImage = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
    ({ imageUrl, id, children, ...rest }, ref) => {
        const transition = useTransitionFactory(imageUrl);

        return (
            <div className="transition-image" ref={ref}>
                {transition((styles, item) => {
                    const placeholder = item.includes(".svg");

                    return (
                        <animated.div
                            className={
                                "transition-image__animated " + (placeholder ? "transition-image__animated--placeholder" : "")
                            }
                            style={styles}
                            {...rest}
                        >
                            {children}
                            <img alt="" src={item} />
                        </animated.div>
                    );
                })}
            </div>
        );
    }
);
