import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    ISlide, selectFiftyFiftySlideImageDimensions, selectImageSlideImageDimensions, selectSequenceStatus, setFiftyFiftySlideImageDimensions, setImageSlideImageDimensions, TextPositionTypeEnum
} from "../../features/sequence/sequenceSlice";
import { useContainerSizeDetect } from "../../hooks/useContainerSizeDetect";

interface Props {
    slideInfo?: ISlide;
    fallbackImage?: string;
}

export function FiftyFiftyTemplate({ slideInfo, fallbackImage }: Props) {
    const dispatch = useAppDispatch();
    const showLoading = useAppSelector(selectSequenceStatus) !== "idle";
    const textFirst = slideInfo?.textPosition !== TextPositionTypeEnum.AfterImage;

    const panelRef = useRef<HTMLDivElement | null>(null);
    const imageRef = useRef<HTMLDivElement | null>(null);

    const currectPanelDimensions = useContainerSizeDetect(panelRef);
    const currentImageDimensions = useContainerSizeDetect(imageRef);

    const panelDimensions = useAppSelector(selectImageSlideImageDimensions);
    const imageDimensions = useAppSelector(selectFiftyFiftySlideImageDimensions);  
    
    const imageUrl = slideInfo && slideInfo.images ? slideInfo?.images[0] : fallbackImage;

    useEffect(() => {
        if (showLoading && !panelDimensions && !imageDimensions && currectPanelDimensions && currentImageDimensions) {
            dispatch(setFiftyFiftySlideImageDimensions(currentImageDimensions));
            dispatch(setImageSlideImageDimensions(currectPanelDimensions));
        }
    }, [dispatch, showLoading, currentImageDimensions, currectPanelDimensions, panelDimensions, imageDimensions]);

    return (
        <div id="fiftyfifty"
            className="fifty-fifty"
            data-reverse={(!!textFirst).toString()}
            style={{
                backgroundColor: slideInfo?.backgroundColour,
                color: slideInfo?.fontColour
            }}
            ref={panelRef}
        >
            <div className="fifty-fifty__panel">
                <div className="text-content">
                    <h1 className="template-heading-text">{slideInfo?.header}</h1>
                    <div className="template-sub-text" dangerouslySetInnerHTML={{ __html: slideInfo?.text || "" }} />
                </div>
            </div>
            <div className="fifty-fifty__panel" ref={imageRef}>
                {imageUrl && <img
                    alt=""
                    src={imageUrl}
                    data-testid="fiftyfiftyimage"
                />}
            </div>
        </div>
    );
}
