import { useEffect, useState } from "react";

export const useContainerSizeDetect = (ref: React.RefObject<HTMLDivElement>) => {
    const [width, setWidth] = useState<number>();
    const [height, setHeight] = useState<number>();

    useEffect(
        () => {
            if (ref.current) {
                setWidth(Math.round(ref.current.offsetWidth));
                setHeight(Math.round(ref.current.offsetHeight));
            }
        },
        [ref]
    );

    return width && height ? { width, height } : undefined;
};
