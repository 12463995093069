export const responseBody = (slides: Record<string, any>[]) => {
    return {
        Id: 1,
        Name: "Main Sequence",
        ShowMapTransitions: false,
        ContactDetails: null,
        Slides: slides,
        SolicitorId: 1070,
        SolicitorName: "Ledingham Chalmers LLP",
        SolicitorLogo: "https://local.aspc.co.uk/solicitor/1070/3415749.jpg",
        FontColour: "#FFFFFF",
        PageBackgroundColour: "#A2C535",
        HeaderBackgroundColour: "#31184F",
        FallBackImageUrl: "https://local.aspc.co.uk/assets/images/photo-placeholder.svg",
    };
};

export const fiftyFiftySlide = {
    SlideType: "FiftyFifty",
    Data: {
        Id: 152,
        Header: "Test FiftyFifty Header",
        Text: "<p>Test FiftyFifty Text Content</p>",
        Url: "https://staging-cdn.aspc.co.uk/resources/office/Sequence/29.jpg",
        Border: true,
    },
};

export const videoSlide = {
    SlideType: "Video",
    Data: {
        Id: 151,
        Url: "jlYad3whws8",
    },
};

export const propertySlide1 = {
    SlideType: "Property",
    Data: {
        Id: 408805,
        Address1: "Flat A,  57 Rose Street",
        Address2: "Aberdeen, AB10 1UB",
        Bedrooms: 1,
        Bathrooms: 1,
        PublicRooms: 1,
        Description: null,
        Price: " Rent £400",
        Latitude: 57.1452448,
        Longitude: -2.1123312,
        Images: [
            "https://staging-cdn.aspc.co.uk/resources/office/Property/353571/Image/4731537.jpg",
            "https://staging-cdn.aspc.co.uk/resources/office/Property/353571/Image/4731532.jpg",
            "https://staging-cdn.aspc.co.uk/resources/office/Property/353571/Image/4731534.jpg",
            "https://staging-cdn.aspc.co.uk/resources/office/Property/353571/Image/4731539.jpg",
            "https://staging-cdn.aspc.co.uk/resources/office/Property/353571/Image/4731538.jpg",
        ],
        ImageCount: 5,
        UnderOffer: false,
        Closed: true,
        ClosedMessage: "Leased",
    },
};
export const propertySlide2 = {
    SlideType: "Property",
    Data: {
        Id: 408806,
        Address1: "Flat A,  58 Rose Street",
        Address2: "Aberdeen, AB10 1UB",
        Bedrooms: 1,
        Bathrooms: 1,
        PublicRooms: 1,
        Description: null,
        Price: " Rent £400",
        Latitude: 57.1452448,
        Longitude: -2.1123312,
        Images: [
            "https://staging-cdn.aspc.co.uk/resources/office/Property/353571/Image/4731537.jpg",
            "https://staging-cdn.aspc.co.uk/resources/office/Property/353571/Image/4731532.jpg",
            "https://staging-cdn.aspc.co.uk/resources/office/Property/353571/Image/4731534.jpg",
            "https://staging-cdn.aspc.co.uk/resources/office/Property/353571/Image/4731539.jpg",
            "https://staging-cdn.aspc.co.uk/resources/office/Property/353571/Image/4731538.jpg",
        ],
        ImageCount: 5,
        UnderOffer: false,
        Closed: false,
        ClosedMessage: "Leased",
    },
};

export const imageSlide = {
    SlideType: "Image",
    Data: {
        Id: 29,
        Url: "https://staging-cdn.aspc.co.uk/resources/office/Sequence/29.jpg",
    },
};

export const textSlide = {
    SlideType: "Text",
    Data: {
        Id: 131,
        Header: "Test Text Header",
        Text: "<p>Test Text Content</p>",
    },
};
